import Resume from '../../files/resume.pdf';
import './Header.css';

export default function Header() {
  return (
    <header>
      <div id='header-content'>
        <h1>Megan Paffrath</h1>
        <ul>
          <li>
            <a href={Resume} target='_blank'>
              Resume
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
}
