import { FaPlay } from 'react-icons/fa';
import './Project.css';

export default function Project({ img, title, desc, link }) {
  return (
    <div className='project'>
      <img src={img} alt='' />
      <div className='projDesc'>
        <h1>{title}</h1>
        <p>{desc}</p>
        {link ? (
          <a className='view-link' href={link} target='_blank'>
            <FaPlay /> View Live
          </a>
        ) : null}
      </div>
    </div>
  );
}
