import './Footer.css';
import { FaGithub } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';

export default function Footer() {
  return (
    <footer>
      <h1>Megan Paffrath</h1>
      <div>
        <a
          className='footer-button'
          href='https://github.com/MeganPaffrath'
          target='_blank'
        >
          <FaGithub />
        </a>
        <a
          className='footer-button'
          href='https://www.linkedin.com/in/meganpaffrath/'
          target='_blank'
        >
          <FaLinkedinIn />
        </a>
      </div>
    </footer>
  );
}
