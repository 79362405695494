import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header.jsx';
import Footer from './components/Footer/Footer.jsx';
import IntroBlurb from './components/IntroBlurb/IntroBlurb.jsx';
import Projects from './components/Projects/Projects.jsx';

function App() {
  return (
    <>
      <Header />
      <div id='page-content'>
        <IntroBlurb />
        <Projects />
      </div>
      <Footer />
    </>
  );
}

export default App;
