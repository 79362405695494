import Project from './Project/Project';
import RecipeImg from './imgs/recipe-app.png';
import MuseumOfCandyImg from './imgs/museum-of-candy.png';
import AxelessonsImg from './imgs/axelessons.png';
import TimerIcon from './imgs/timer.png';
import MyIcon from '../../imgs/engineer-img.png';
import './Projects.css';

export default function Projects() {
  return (
    <div id='projects'>
      <h1>Projects</h1>
      <Project
        title='Axelessons'
        img={AxelessonsImg}
        desc='This React Application utilizes React Router to create multiple pages. It also utilizes React components to load lesson content.'
        link='https://axelessons.com'
      />
      <Project
        title='Museum Of Candy'
        img={MuseumOfCandyImg}
        desc='This is a React simple page that displays usage of HTML, CSS, and Bootstrap!'
        link='https://meganpaffrath.github.io/museum-of-candy/'
      />
      <Project
        title='Recipe Catalog'
        img={RecipeImg}
        desc='This is a React application that allows users to search recipes by title, ingredient, type, and temperature.'
        link='https://meganpaffrath.github.io/recipe-book/'
      />
      <Project
        title='Time Tracker Web Application'
        img={TimerIcon}
        desc='This MERN Stack Application (MongoDB, Express, React, Node) utilized JWTs to keep track of user sessions. The backend API handled account registration, login, logout, and read/write operations of time logs to the database.'
      />
      {/* <Project
        title='This Webpage'
        img={MyIcon}
        desc='A simple React page that shows projects worked on.'
      /> */}
    </div>
  );
}
