import EngineerImage from '../../imgs/engineer-img.png';
import { FaReact } from 'react-icons/fa';
import { IoLogoVue } from 'react-icons/io5';
import { DiPhp } from 'react-icons/di';
import { FaLaravel } from 'react-icons/fa';
import { IoLogoJavascript } from 'react-icons/io5';
import { TbSql } from 'react-icons/tb';
import { FaGithub, FaLinkedinIn, FaDev } from 'react-icons/fa';
// import { FaDev } from 'react-icoms/fa';
// import { FaLinkedinIn } from 'react-icons/fa';
import './IntroBlurb.css';

function timeOfDay() {
  const date = new Date();
  const hour = date.getHours();

  if (hour < 12) {
    return '☀️ Good morning';
  } else if (hour < 17) {
    return '🌼 Good afternoon';
  } else {
    return '✨ Good evening';
  }
}

export default function IntroBlurb() {
  return (
    <>
      <div id='intro-blurb'>
        <img src={EngineerImage} alt='Software Engineer' />
        <div id='intro-text'>
          <h1>{timeOfDay()}, I'm Megan Paffrath!</h1>
          <p>
            I am a passionate full stack software engineer. I enjoy learning
            daily, growing my skillset, and working on challenging problems.
          </p>

          <div id='intro-icons'>
            <IoLogoJavascript />
            <FaLaravel />
            <DiPhp />
            <IoLogoVue />
            <FaReact />
            <TbSql />
          </div>
          <div>
            <a
              className='intro-button'
              href='https://github.com/MeganPaffrath'
              target='_blank'
            >
              <FaGithub />
            </a>
            <a
              className='intro-button'
              href='https://www.linkedin.com/in/meganpaffrath/'
              target='_blank'
            >
              <FaLinkedinIn />
            </a>
            <a
              className='intro-button'
              href='https://dev.to/meganpaffrath'
              target='_blank'
            >
              <FaDev />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
